var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bt-anggota pa-5"},[_c('Header',{staticClass:"text-h6 font-weight-bold mb-4",attrs:{"title":"Kumpulan","titleIcon":"mdi-account-group"}}),_c('div',{staticClass:"py-3 bth-rembug-box"},[_c('v-autocomplete',{staticClass:"mb-4",attrs:{"color":_vm.$vuetify.theme.dark
          ? 'background-color: white'
          : 'background-color: black',"solo":"","label":"Hari","hide-details":"","items":_vm.opt_hari,"item-text":"day_name","item-value":"day_code"},on:{"change":function($event){return _vm.getRembug(_vm.selectedItem)}},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.transaction_date,"transition":"scale-transition","min-width":"auto","color":_vm.$vuetify.theme.dark
          ? 'background-color: white'
          : 'background-color: black'},on:{"update:returnValue":function($event){_vm.transaction_date=$event},"update:return-value":function($event){_vm.transaction_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","label":"Tanggal","readonly":""},model:{value:(_vm.transaction_date),callback:function ($$v) {_vm.transaction_date=$$v},expression:"transaction_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateShow),callback:function ($$v) {_vm.dateShow=$$v},expression:"dateShow"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.transaction_date),callback:function ($$v) {_vm.transaction_date=$$v},expression:"transaction_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateShow = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.transaction_date)}}},[_vm._v(" OK ")])],1)],1),_vm._l((_vm.rembug),function(rbg,rbgIndex){return _c('v-card',{key:rbgIndex,staticClass:"pa-5 mb-3"},[_c('h6',{staticClass:"text-h6 mb-1 font-weight-bold text-center"},[_vm._v(" Kp. "+_vm._s(rbg.cm_name)+" ")]),_c('h1',{staticClass:"text-h4 font-weight-black purple--text text--lighten-1 d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-body-2 font-weight-black grey--text"},[_vm._v("Jumlah Anggota")]),_vm._v(" "+_vm._s(rbg.jumlah)+" ")]),_c('p',{staticClass:"text-caption mb-4"},[_vm._v(" "+_vm._s(rbg.nama_desa)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":_vm.transaction_date !== null
                ? `/angsuran/kelompok/${rbg.cm_code}/${rbg.branch_id}/${_vm.transaction_date}`
                : ''}},[_c('v-btn',{staticClass:"purple lighten-1 white--text rounded-lg",attrs:{"block":"","type":"button","disabled":_vm.transaction_date === null}},[_vm._v(" Lihat Kumpulan ")])],1)],1)],1)],1)})],2),_c('v-row',[_c('v-col',{staticClass:"pb-5 mb-5",attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":"/dtl/dashboard"}},[_c('v-btn',{staticClass:"purple lighten-1 white--text",attrs:{"block":""}},[_vm._v("Kembali ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000},model:{value:(_vm.alert.show),callback:function ($$v) {_vm.$set(_vm.alert, "show", $$v)},expression:"alert.show"}},[_vm._v(" "+_vm._s(_vm.alert.msg)+" ")]),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }